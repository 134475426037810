/* .gellary_container_main{
    text-align: center;
    font-family: sans-serif;
}
.gellary_heading_h2{
    color: #f5b921;
    font-size: xx-large;
    text-shadow: 2px 2px 4px #000000;
}
.gellary_heading_h1{
    font-size: xxx-large;
    text-shadow: 2px 2px 4px white;
}
.gellary_img{
    width: 300px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.gellary_btn{
    height: 50px;
    width: 180px;
    background-color: black;
    color: white;
    margin: 2px;
    font-weight: 800;
    border: 2px solid white;
}
.gellary_btn:hover{
    background-color: #f5b921;
    color: black;
}
.gellary_container_goal{
    column-gap: 50px;
    padding: 10px;
} */












.gellary_container_main{
    text-align: center;
    font-family: sans-serif;
    margin: 0% 6% 0% 6%;
}
.gellary_heading_h2{
    color: #f5b921;
    font-size: xx-large;
    /* text-shadow: 2px 2px 4px #000000; */
}
.gellary_heading_h1{
    font-size: xxx-large;
    /* text-shadow: 2px 2px 4px white; */
}
.gellary_btn{
    height: 50px;
    width: 180px;
    background-color: black;
    color: white;
    margin: 2px;
    margin-left: 10px;
    font-weight: 800;
    border: 2px solid white;
}
.gellary_btn:hover{
    background-color: #e0a207 !important;
    color: black !important;
}
.gellary_container_goal_col{
    width: 25%;
    padding: 10px;
}
.see_more_button{
    background-color: black;
    color: white;
    font-weight: 500;
    border: 2px solid white;   
    float: right; 
    padding: 5px 20px 5px 20px;
    border-radius: 10px;

}
.gellary_img{
    width: 100%;
    height: 200px;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}
@media screen and (max-width: 700px) {
    .gellary_heading_h1{
        font-size: x-large;
        /* text-shadow: 2px 2px 4px white; */
    }
    .gellary_container_goal_col{
        width: 50%;
        padding: 10px;
    }
}