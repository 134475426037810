.projectvideo{
    width: 100% !important;
    height: 650px !important;
    border: 1px solid black;
    position: relative;
    overflow: hidden;
}
.projectvideo video{
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
}
.projectparagraph{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    background: rgba(0, 0, 0, 0.5);
}
.studioname{
    margin-left: 37%;
    text-align: center;
    width: 30%;
    height: 32%;
    row-gap: -60px;
    position: relative;
    display: flex;
    align-items: end;
   
}
.studioname h1{
    text-align: center;
    font-weight: 900;
    font-size: 40px;
    color: white;

}
.studioname h2{
    top: 170px;
    position: absolute;
    color: #f5b921;
}
.studioname1{
    width: 30%;
    margin-left: 40px;
    height: 150px;
    font-size: 17px;
    padding-top: 25px;

}
.btn{ 
    width: 30%;
    margin-left: 40px;
    height: 150px;
    font-size: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.btn button{
    width: 45%;
    height: 40px;
    background: transparent;
    border: 2px solid white;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.btn button:hover{
    background: yellow;
    color: black;
    border: none;
}

#landing_p_tag{
    color: white;
}


@media only screen and (max-width: 900px) {
    body {
      background-color: lightblue;
    }
    .studioname{
        margin-left: 40px;
        width: 50%;
        height: 32%;
        row-gap: -60px;
        position: relative;
        display: flex;
        align-items: end;
    }
    .studioname1{
        width: 50%;
        margin-left: 40px;
        height: 150px;
        font-size: 20px;
        padding-top: 25px;
    }
    .btn{ 
        width: 50%;
        justify-content: space-evenly;
        margin-left: 0;
    }
    .btn button{
        width: 40%;
        height: 40px;
        
    }
  }




@media only screen and (max-width: 600px) {
    body {
      background-color: white;
    }
    .studioname{
        width: 100%;
        
        
    }
    .studioname1{
        width: 80%;
    }
    .btn{ 
        width: 80%;
        justify-content: space-evenly;
        margin-left: 30px;

    }
    .btn button{
        width: 45%;
        height: 40px;
        
    }
  }