/* html{
    --serif: 'spectral', serif;
    --sans-serif: 'hk-grotesk', sans-serif;
    --font-size: 13px;
    --font-size-notes: 9px;
    --line-height: 15px;
    --line-height-notes: calc(15px*2/3);
    --indent: 6mm;
    --baseline: 15px;
  } */

  


  @page {
    /* size: ; */
  }
  @media screen {
    .Single_party_bill{
      display: none;
    }
  }
  @media  print {
    .Single_party_bill{
      display: block;
    }
    table{

      margin: 0px;
    }
    .baseline .pagedjs_page {
      background-image:  repeating-linear-gradient(180deg, transparent  0, transparent 14px , rgba(0,255,0,0.7) var(--baseline)) ;
      background-size: cover;
      background-position: 20px , 


    }
   
  }
.footer {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 140px;
    text-align: center;
    z-index: -1;
  }
  @media screen and (max-width: 700px){

    .footer {
      position: fixed;
      bottom: 0px;
      left: 0;
      right: 14px;
      text-align: center;
      z-index: -1;
    }
  }
  

  @page cover {
    margin: 0;
    background: red;
    background-position: center;
    background-size: 104%;
    background-repeat: no-repeat;
  
    @top-left{ content: none; }
    @top-center{ content: none; }
    @top-right{ content: none; }
    @bottom-left{ content: none; }
  }
  
  @page:blank {
    @top-left-corner{ content: none; }
    @top-left{ content: none; }
    @top-center{ content: none; }
    @top-right{ content: none; }
    @top-right-corner{ content: none; }
    @right-top{ content: none; }
    @right-middle{ content: none; }
    @right-bottom{ content: none; }
    @bottom-right-corner{ content: none; }
    @bottom-right{ content: none; }
    @bottom-center{ content: none; }
    @bottom-left{ content: none; }
    @bottom-left-corner{ content: none; }
    @left-bottom{ content: none; }
    @left-middle{ content: none; }
    @left-top{ content: none; }
  }
  
  @page:left {
    margin-left:3mm;
    margin-right:3mm;
    margin-top: 13px;
    margin-bottom: 13px;
  
    @top-left{
      content: counter(page);
      font-weight: 400;
      font-family: var(--serif);
      font-size: var(--font-size);
      font-weight: normal;
      line-height: var(--line-height);
      vertical-align: bottom;
      margin-bottom: 34px;
      font-variant-numeric: oldstyle-nums;
    }
  
    @top-center{
      content: string(booktitle);
      font-weight: 400;
      font-family: var(--serif);
      font-size: var(--font-size);
      font-weight: normal;
      line-height: var(--line-height);
      vertical-align: bottom;
      margin-bottom: 74px;
      font-variant-numeric: oldstyle-nums;
      font-variant: small-caps;
      width: 95mm;
      text-align: left;
    }
  }
  
  @page:right {
    margin-left:3mm;
    margin-right:3mm;
    margin-top: 13px;
    margin-bottom: 13px;
    @top-right{
      content: counter(page);
      font-weight: 300;
      font-family: var(--serif);
      font-size: var(--font-size);
      font-weight: normal;
      line-height: var(--line-height);
      vertical-align: bottom;
      margin-bottom: 14px;
      font-variant-numeric: oldstyle-nums;
      text-align: right;
    }
  
    @top-center{
      content: element(shorter);
      vertical-align: bottom;
      text-align: right;
      margin-bottom: 1px;
      width: 95mm;
    }
  }
  
  .sidenote, .footnote {
      display:none!important;
  }
  
  .footnote::footnote-call, .footnote::footnote-marker{
      display:none!important;
  }
  
  .part h2, #appendices h2,
  section[data-type="half-title"],
  section[data-type="titlepage"],
  section[data-type="copyright"],
  section[data-type="dedication"],
  section[data-type="epigraph"],
  #toc {
    margin-left: 0mm /*center the page*/
  }
  
  .part h2, #appendices h2{
    page: part;
  }
  
  /* running elements */
  
  #cover h1 {
    string-set: booktitle content(text);
  }
  
  #cover {
    page: cover;
  }
  
  .shorter{
    position: running(shorter);
    font-weight: 400;
    font-family: var(--serif);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: var(--line-height);
    text-align: right;
    font-style: italic;
    font-variant-numeric: oldstyle-nums;
  }
  
  
  /* flexbox */
 