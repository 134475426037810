.product_col{
    width: 44%;
    margin: 3%;
    height: 300px;
    
}
.product_col img{
    height: 100%;
    width: 100%;
}
.panel-header {
    color: white;
  }
  
  .ant-collapse-arrow {
    color: white !important; /* Use !important to override default styles */
  }
  
@media screen and (max-width: 700px) {

    .product_col{
        width: 90%;
        margin: 1%;   
    }
}