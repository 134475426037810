.about_first_row{
    width: 50%;
}
.about_first_row img{
    border-radius: 15px;
}
.about_second_row{
    margin-top: 5%;

   
}
.about_second_row_col{
    width: 30%;
    margin-right: 3%;
       
}
.list-style-type{
    list-style-type: disc !important;
    font-family: sans-serif;

}

.about_card {
    /* background-image: url('../../../assets/image/vision.jpeg'); */
    background-color: black;
    background-size: cover;
    color: white;
    
}

@media screen and (max-width: 700px) {
    .about_first_row{
        width: 100%;
    }
    .about_second_row_col{
        width: 990%;
        margin:1%;
           
    }
}