/* .slider_main_container{
    background-color: #aba79d;
} */

.slide_img{
    height:210px;
    width:260px;
}
.card{
    height:300px;
    width:auto;  
    margin-left: 10px;
}

@media screen and (max-width: 1100px) {
    .slide_img{
        height:210px;
        width:320px;
    }
}


@media screen and (max-width: 700px) {
    .slider_main_container{
        background-color: white;
    }
    .slide_img{
        height:210px;
        width:350px;
    }
}
